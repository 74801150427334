// https://docs.stripe.com/payments/checkout/client
import React from "react";

async function checkout() {
    window.open(
        "https://buy.stripe.com/test_7sIeWr5rb0r637i144",
        "_blank",
        "noreferrer"
    );
}

export const Checkout = () => {
    return <button onClick={() => checkout()}>Checkout</button>;
};
