import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Grid, LinearProgress } from "@mui/material";

class MessageDto {
  isUser: boolean;
  content: string;

  constructor(isUser: boolean, content: string) {
    this.isUser = isUser;
    this.content = content;
  }
}

interface MessageProps {
  message: MessageDto;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  return (
    <div style={{ textAlign: message.isUser ? "right" : "left", margin: "8px" }}>
        <div style={{ marginLeft: message.isUser ? "8px" : "0", marginRight: message.isUser ? "0" : "8px", display: "flex",
          justifyContent: message.isUser ? "right" : "left",
          alignItems: "center" }}>
          {message.isUser ? 
            <>
              <b>You</b>
              <img
              src={"https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="}
              alt={"User Profile"}
              style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            />
            </>
            :
            <>
            <b>Cassandra</b>
              <img
              src={"https://media.istockphoto.com/id/1010001882/vector/%C3%B0%C3%B0%C2%B5%C3%B1%C3%B0%C3%B1%C3%B1.jpg?s=612x612&w=0&k=20&c=1jeAr9KSx3sG7SKxUPR_j8WPSZq_NIKL0P-MA4F1xRw="}
              alt={"Bot Profile"}
              style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            />
            </>
          }
        </div>
      <div
        style={{
          color: message.isUser ? "#ffffff" : "#000000",
          backgroundColor: message.isUser ? "#1186fe" : "#eaeaea",
          padding: "15px",
          borderRadius: "8px",
        }}
      >
        {message.content.split("\n").map((text, index) => (
          <>
            {text}
            <br />
          </>
        ))}
      </div>
    </div>
  );
};

export const Chat: React.FC = (conversation_id) => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<MessageDto>>(new Array<MessageDto>());
  const [input, setInput] = useState<string>("");

  useEffect(() => {
    setMessages([])
  }, []);

  const createNewMessage = (content: string, isUser: boolean) => {
    const newMessage = new MessageDto(isUser, content);
    return newMessage;
  };

  const handleSendMessage = async () => {
      setIsWaiting(true)
      const newMessage = createNewMessage(input, true);
      console.log(input)
      setMessages(messages => [...messages, newMessage]);
      setInput("");

      const url = `${process.env.REACT_APP_API_URL}`;
      console.log(url)
      let lambda_response = await fetch(url, {
          method: "POST",
          body: JSON.stringify({
              message: input, 
              conversation_id: conversation_id 
          })
      });

      let response = await lambda_response.json();
      setIsWaiting(false);

      console.log(response)

      // Assuming `response.message` contains the response message text
      // This part needs to be adjusted based on the actual structure of your response
      if (response && response.response) {
          setMessages(messages => [...messages, createNewMessage(response.response, false)]);
      }
  };

  // detect enter key and send message
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && input !== "") {
      handleSendMessage();
    }
  };

  return (
    <Container style={{ overflowY: "auto", maxHeight: "85vh" }}>
      <Grid container direction="column" spacing={2} paddingBottom={5}>
        {messages.map((message, index) => (
          <Grid item alignSelf={message.isUser ? "flex-end" : "flex-start"} key={index}>
            <Message key={index} message={message} />
          </Grid>
        ))}
        <Grid item>
          <br/>
          <TextField
            label="Type your message"
            variant="outlined"
            disabled={isWaiting}
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          {isWaiting && <LinearProgress color="inherit" />}
        </Grid>
        {!isWaiting && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSendMessage} disabled={!input || isWaiting}>
              Send
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
