import React, { useState, useEffect } from "react";
import "./App.css";
// Removed AWS Amplify and Authenticator related imports
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Checkout } from "./pages/checkout.js";
import { Chat } from "./pages/chat.tsx";
import HumanateLogo from "./humanate-logo.png";

import { v4 as uuidv4 } from "uuid";

// Removed Amplify.configure(awsconfig);

function App() {
    const [conversationId, setConversationId] = useState("");
    useEffect(() => {
        setConversationId(uuidv4());
    }, []);

    return (
        <>
            <div style={{ display: "flex", margin: "15px" }}>
                <img
                    src={HumanateLogo}
                    alt="logo"
                    style={{
                        height: "50px",
                        marginLeft: "20px",
                        marginTop: "20px",
                    }}
                />

                <div
                    style={{
                        backgroundColor: "#FEEFB3",
                        display: "flex",
                        margin: "20px auto",
                        marginLeft: "10px",
                        marginTop: "20px",
                    }}
                >
                    <p
                        style={{
                            color: "#9F6000",
                            marginLeft: "20px",
                            marginRight: "20px",
                            textAlign: "center",
                        }}
                    >
                        <b>
                            Disclaimer: This demonstration utilizes eCW sandbox
                            data and is not HIPAA compliant. Do not use this
                            demo with actual patient data.
                        </b>
                    </p>
                </div>
            </div>
            <BrowserRouter>
                <Routes>
                    <Route path="/checkout" element={<Checkout />} />
                    <Route
                        path="/chat"
                        element={<Chat conversationId={conversationId} />}
                    />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
